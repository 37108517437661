/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 45px !important;
    height: 30px;
    right: 25px;
    top: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background:#c5c5c5;
    border-radius: 3px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #3d3d3d;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #e9e9e9;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #373a47;
    background: rgb(49, 49, 49);
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill:#e9e9e9;
}

/* Wrapper for item list */
.bm-item-list {
    color: #e9e9e9;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3); 
}